function cartPage() {
    loadQtyInputs();
    
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function() {
                rewixUpdateCart($form);
                window.location.href = window.location.pathname;
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.btn-update').on('click', function(event) {
        event.preventDefault();
        const $form = $(this).closest('form');
        $form.trigger('submit');
    });

    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $button = $(this);
        var data: {[param: string]: string | number | string[]} = {};
        data[$button.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $button.attr('data-action'),
            data: data,
            success: function() {
                const container = $button.closest('form');
                rewixUpdateCart(container, true);
                window.location.href = window.location.pathname;
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $(".burn-coupon-form").on('submit', function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").value<string>().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = window.location.pathname;
                    }
                    else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('.carrier-form').on('change',function() {
        $.ajax({
            method: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = window.location.pathname;
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    $('.cart-remove-all-products').on('click', function(event) {
    
        if (confirm(msg['MSG_DELETEALLPRODUCTS'])) {
            const promises = $('.cart-update').toArray().map(item => new Promise<void>(resolve => {
                const form = $(item);
                const inputs = form.find('.quantity-input');
                let data: {[param: string]: number} = {};
    
                inputs.each(function () {
                    const name = $(this).attr('name');
                    data[name] = 0;
                });
    
                $.ajax({
                    type: 'POST',
                    url: '/restful/shopping/public/checkout/addtocart',
                    data: data,
                    success: function() {
                        rewixUpdateCart(form, true);
                    },
                    error: function() {
                        showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                    },
                    complete: function() {
                        resolve();
                    }
                });
            }));

            Promise.all(promises)
                .then(() => window.location.href = window.location.pathname)
        }
    });
}