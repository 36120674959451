function homePage() {
    //mobile menu behaviour

    $(".menu-collapsed").click(function () {
        $(this).toggleClass("menu-expanded");
    });

    /*   Accordion menu footer */

    var acc = document.getElementsByClassName("accordion");
    var i: number;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
        });
    }

    //banner clickable
    $('*[data-link]').click(function () {
        window.location.href = $(this).attr('data-link')
    });

    //home logged blog preview

    $('.post-images').first().removeClass('hide');
    $('.post-images-desktop').first().removeClass('hide');

    $('li.target').mouseover(function () {

        let cmsCode = $(this).attr('data-code');

        $('.post-images').addClass('hide')
        $('.post-images[data-code=' + cmsCode + ']').removeClass('hide')

        $('.post-title').removeClass('bolder')
        $('.post-title[data-code=' + cmsCode + ']').addClass('bolder')

    });

    //Not logged mobile menu

    function removeScrollBarPushing() {
        const offsetY = document.documentElement.scrollTop;
        let i = 0;
        const time = setInterval(function () {
            if (i++ < 2) {
                clearInterval(time);
            }
            document.documentElement.scrollTop = offsetY;
        }, 1);
    }

    // open sidenav
    $('#nav-toggle-btn').click(function () {
        $('#sidenav').addClass('show');
        removeScrollBarPushing();
    });

    // close sidenav
    $('#sidenav .closebtn').click(function () {
        $('#sidenav').removeClass('show');
    });

}