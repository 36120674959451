function checkoutPage() {
    'use strict';

    function submitAddressEditForm(form: JQuery<any>, callback: (success: boolean, msg?: string) => void) {
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function (data) {
                callback(data.success, data.success ? "" : data.errors.message);
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    $('#dispatch-address-form').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function (form, event) {
            event.preventDefault();
            const $form = $(form)
            if (!$('#clausola1').is(':checked')) {
                showMessageBox('danger', msg['ERR_PRIVACY']);
                $('#clausola1').addClass('error')
            }
            else {
                submitAddressEditForm($form, function () {
                    const billingForm = $('#billing-address-form');
                    $.ajax({
                        type: 'POST',
                        url: '/restful/shopping/public/checkout/setproperty',
                        data: billingForm.is(':visible') ? billingForm.find('[name=type-invoice]').toArray().map(function (el) {
                            return `key=${(el as HTMLInputElement).name}&value=${(el as HTMLInputElement).value}`
                        }).join() : `key=type-invoice&value=no-fattura`,
                        dataType: 'json',
                        success: function (data) {
                            if (data.success) {
                                const dataLayer = window.dataLayer as object[];
                                if (dataLayer) {
                                    dataLayer.push({ ecommerce: null });
                                    pushGTMEventWithCallback(500, {
                                        event: 'add_shipping_info',
                                        ecommerce: (window as any).ecommerce,
                                        eventCallback: GTMCallback(() => {
                                            if (billingForm.is(':visible')) {
                                                billingForm.trigger('submit');
                                            }
                                            else {
                                                window.location.href = $form.data('nextstep')
                                            }
                                        })
                                    });
                                }
                                else {
                                    if (billingForm.is(':visible')) {
                                        billingForm.trigger('submit');
                                    }
                                    else {
                                        window.location.href = $form.data('nextstep')
                                    }
                                }
                            }
                        },
                        error: function () {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    })
                });
            }
        }
    });

    $('#country').on('change', function () {
        const $this = $(this)
        $.ajax({
            method: 'POST',
            url: '/restful/carrier/change',
            data: {
                country_id: $(this).val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    const form = $this.closest('form')
                    const inputs = form.find('input,select,textarea').filter(function () {
                        return $(this).value<string>(val => val.toString()) != ""
                    })
                    $.ajax({
                        method: form.attr('method'),
                        url: form.attr('action'),
                        dataType: 'json',
                        data: inputs.serialize(),
                        success: function (data) {
                            if (data.success) {
                                window.location.reload();
                            }
                            else {
                                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                            }
                        },
                        error: function () {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    })
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    })

    monitorInput('#billing-address-form', 'type-invoice', select => select.value<string>() === 'privato', () => {
        $('#billing-address-form').find('[name=cfpiva], [name=pec], [name=sdi]').disabled(true).closest('.columns').addClass('hide');
        $('#billing-address-form').find('[name=fiscal_code]').disabled(false).closest('.columns').removeClass('hide');
    }, () => { })

    monitorInput('#billing-address-form', 'type-invoice', select => select.value<string>() === 'azienda', () => {
        $('#billing-address-form').find('[name=fiscal_code]').disabled(true).closest('.columns').addClass('hide');
        $('#billing-address-form').find('[name=cfpiva], [name=pec], [name=sdi]').disabled(false).closest('.columns').removeClass('hide');
    }, () => { })

    $('#btn-continue').on('click', function () {
        $('#dispatch-address-form').trigger('submit')
    })

    $('#billing-address-form').validate({
        submitHandler: function (form, event) {
            event.preventDefault();
            submitAddressEditForm($(form), function (success, error) {
                if (success) {
                    window.location.href = $(form).data('nextstep')
                }
                else {
                    if ((error as keyof Translations) in msg) {
                        showMessageBox('danger', msg[error as keyof Translations]);
                        $('#clausola1').addClass('error');
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
            });
        }
    });

    $('#copy-dispatch').on('click', function () {
        copyFormInputs('#billing-address-form', '#billing-address-form', {
            'addressee_disp': 'addressee',
            'cap_disp': 'cap',
            'city_disp': 'city',
            'number_disp': 'number',
            'prov_disp': 'prov',
            'street_disp': 'street',
            'country_disp': 'country_id',
        });
    });

    $('[name*="country"]').not('[type=hidden]').on('change', updateProvinces);
    updateProvinces();

    $('#payment-paypal-form, #payment-scalapay-form').validate({
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        window.location.href = data.message;
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            })
        }
    });

    $('#booking-btn').on('click', function (event) {
        const btn = this;
        if (window.dataLayer) {
            event.preventDefault();
            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: "add_payment_info",
                ecommerce: ((window as any)['ecommerce'] as Function)('Booking'),
                eventCallback: GTMCallback(function () {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });
}