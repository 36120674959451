function headerComponent() {
    $('header')
        .on('toggle:was-opened', function(event, selector) {
            if (selector == '#mobile-menu') {
                $('header')
                    // .addClass('bg-light-grey-tablet')
                    .find('.show-on-mobile-menu-closed')
                        .hide()
                        .closest('header')
                    .find('.show-on-mobile-menu-open')
                        .show();
            }
            else if(selector == '#mobile-search') {
                $('#mobile-search input[type=search]')[0].focus();
            }
        })
        .on('toggle:was-closed', function(event, selector) {
            if (selector == '#mobile-menu') {
                $('header')
                    // .removeClass('bg-light-grey-tablet')
                    .find('.show-on-mobile-menu-closed')
                        .show()
                        .closest('header')
                    .find('.show-on-mobile-menu-open')
                        .hide();
            }
        });

    $('#mobile-menu-back').on('click', function() {
        $('#mobile-menu .mobile-menu-item.layer-1.open').trigger('click');
    });

}

