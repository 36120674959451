function menuComponent() {
    const mobileMenu = $('#mobile-menu');
    mobileMenu
        .on('mobile-menu:toggle-item', function(event, id) {
            const menu = $(this);
            const item  = menu.find(`.mobile-menu-item.layer-1#${id}`);

            if (exists(item)) {
                const otherItems = menu.find('.mobile-menu-item.layer-1').not(item);

                if (otherItems.is(':visible')) {
                    otherItems.parent().slideUp();
                }
                else {
                    otherItems.parent().slideDown();
                }
            }
        })
        .on('toggle:was-opened', function(event, selector) {
            if (selector == '#mobile-menu') {
                mobileMenu.css('--header-height', $('header').outerHeight());
                blockPageScroll();
            }
            else if (exists($(selector).siblings('.layer-1'))) {
                $('#mobile-menu-back').css('visibility', 'visible');
            }
        })
        .on('toggle:was-closed', function(event, selector) {
            if (selector == '#mobile-menu') {
                restorePageScroll();
            }
            else if (exists($(selector).siblings('.layer-1'))) {
                $('#mobile-menu-back').css('visibility', 'hidden');
            }
        });

    $(window).on('resize orientationchange', function() {
        mobileMenu.trigger('toggle:close');
    });
}

