function contactComponent() {
    loadCaptcha();

    const contactForm = $('#contact-form');
    const privacy = $("#privacy");

    if (contactForm.length > 0) {
        //Enable or disable button based on if inputs are filled or not
        $('input[required]').on('keyup', function () {
            checkInputs();
        })
        privacy.on('click', function () {
            checkInputs();
        })
        
        checkInputs();

        contactForm.validate();
    }

    function checkInputs() {
        const btnNext = $('#btn-next');

        if (!privacy.prop('checked')) {
            btnNext.prop('disabled', true);
            return false;
        }

        $('input[required], textarea[required]').each(function() {
            if ($(this).val() === '') {
                btnNext.prop('disabled', true);
                return false;
            }
        });
        
        return true;
    }
}

function contactFormCaptcha() {
    const $form = $('#contact-form');
    $form.find('.sent-label').remove();

    if ($form.valid()) {
        $form.addClass('loading');
        $form.find('[type="submit"]').disabled(true);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);

                    $form.append(`<div class="sent-label small-12 columns flex justify-center mt-xxs">
                        <span class="success semibold txt-center">${msg['MSG_MESSAGE_SENT']}</span>
                    </div>`);
                }
                else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
                setTimeout(() => {
                    $form.removeClass('loading');
                    $form.find('[type="submit"]').disabled(false);
                }, 2000);
            }
        });
    }
    else if (!$("#privacy").prop('checked')) {
        showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
    }
    else {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    }
}


function stationeryComponent() {
    loadCaptcha();

    const stationeryForm = $('#stationery-form');
    if (stationeryForm.length > 0) {
        stationeryForm.validate();
    }
}


function stationeryFormCaptcha() {
    const $form = $('#stationery-form');
    $form.find('.sent-label').remove();

    if ($form.valid()) {
            const data = $form.serializeArray();
            if (!data.some((item) => item.name === "isClient")) {
                data.push({ "name": "isClient", "value": "false" });
            }

            $form.addClass('loading');
            $form.find('[type="submit"]').disabled(true);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: data,
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_MESSAGE_SENT']);

                        $form.append(`<div class="sent-label small-12 columns flex justify-center mt-xxs">
                            <span class="success semibold txt-center">${msg['MSG_MESSAGE_SENT']}</span>
                        </div>`);
                    }
                    else {
                        showMessageBox('danger', data.errors.message);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function() {
                    setTimeout(() => {
                        $form.removeClass('loading');
                        $form.find('[type="submit"]').disabled(false);
                    }, 2000);
                }
            });
    }
    else if (!$("#privacy").prop('checked')) {
        showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
    }
    else {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    }
}

