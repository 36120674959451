function catalogPage() {
    'use strict';

    $('html').on('catalog:reload', reloadCatalog);
    $(window).on('popstate', reloadCatalog);

    setupCatalog();
    $('#catalog-container').removeClass('loading');

    function setupCatalog() {
        initToggle('#catalog-wrapper');
        initSearch('#catalog-wrapper');
        initBackgroundImageLazyLoad('#catalog-wrapper')
        setLayoutOptions();
        checkPreferredLayout();
        setSortOptions();
        setFilterOptions();
        setPagination();
        productComponent();
    }

    function reloadCatalog() {
        $('#catalog-container').addClass('loading');
        $.ajax({
            method: 'GET',
            url: window.location.href,
            dataType: 'html',
            success: function (data) {
                const response = $('<div>').append($(data));
                replaceCatalogElements(response, ['#catalog-wrapper']);
                setupCatalog();
            },
            error: function () {
                window.location.reload();
            },
            complete: function () {
                $('#catalog-container').removeClass('loading');
            }
        });

        function replaceCatalogElements(data: JQuery<any>, selectors: JQuerySelector[]) {
            for (let selector of selectors) {
                const element = data.find(selector);
                $(selector).replaceWith(element);
            }
        }
    }

    function pushStateAndReload(event: JQuery.Event) {
        const value = $(this).value<string>();
        const href = $(this).attr('href');
        const url = value || href;

        if (!value) {
            event.preventDefault();
        }

        window.history.pushState({}, '', url);
        $(this).trigger('catalog:reload');
    }


    type CatalogLayout = 'list' | 'grid';

    function checkPreferredLayout() {
        let layout = (localStorage.getItem('catalogLayout') || 'grid') as CatalogLayout;

        switch(layout) {
            case "list":
                $('#list-option').prop('checked', true).trigger('click');
                break;

            case "grid":
                $('#grid-option').prop('checked', true).trigger('click');
                break;
        }

        setCatalogLayout(layout);
    }

    function setCatalogLayout(layout: CatalogLayout) {
        if (layout === 'grid') {
            $('#catalog-wrapper .list-layout').hide();
            $('#catalog-wrapper .grid-layout').fadeIn();
        }
        else {
            $('#catalog-wrapper .grid-layout').hide();
            $('#catalog-wrapper .list-layout').fadeIn();
        }

        localStorage.setItem('catalogLayout', layout);
    }

    function setLayoutOptions() {
        $('#layout-options input[name="options"]').on('change', function () {
            const layout = $(this).value<CatalogLayout>();
            setCatalogLayout(layout);
        });
    }


    function setSortOptions() {
        $('#sort-options a').on('click', pushStateAndReload);
    }


    function setFilterOptions() {
        $('#filters-row [id*="filter"]').on('change', pushStateAndReload);
        $('#filters .filter > a, .current-filter').on('click', pushStateAndReload);
        $('#filters .see-all').on('click', function() {
            $(this).closest('.filters-list').addClass('scroll-view')
                .find('.filter.hide').removeClass('hide');
            $(this).hide();
        });

        $('#mobile-filters .title').on('click', function () {
            const id = $(this).parent().attr('id');
            const menu = $(this).siblings('ul');
            $(this).toggleClass('active');
            menu.slideToggle();
            $('#mobile-filters-container').children().not(`[id=${id}]`).slideToggle();
        });

        $('#mobile-filters [id*="filter"] a')
            .on('click', function () { $(this).trigger('side-panel:close', '#mobile-filters') })
            .on('click', pushStateAndReload);
    }

    
    function setPagination() {
        $('.pagination a')
            .on('click', pushStateAndReload)
            .on('click', () => $(window).scrollTop(0));
    }
}