function wishlistPage(): void {
    $('#copyLink').on('click', function () {
        copyTextToClipboard($('#linkToCopy').text())
            .then(() => {
                showMessageBox('success', msg['MSG_LINK_COPIED'])
            })
            .catch(() => {
                showMessageBox('danger', msg['ERR_LINK_NOT_COPIED'])
            });
    })
}