function profilePage() {
    var currentCountry = $("#country").find('option:selected').data('eu');

    if (currentCountry == 'EU') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').attr('required', 'required');
    } else if (currentCountry == 'IT') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-div').removeClass('hide');
        $('#sdi-div').removeClass('hide');
        $('#cfpiva').attr('required', 'required');
    } else if (currentCountry != undefined) {
        $('#cfpiva-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').removeAttr('required');
    }

    $('#copy-dispatch').on('click', function (event) {

        $('#addressee').val($('#addressee_disp').value<string>())
        $('#cap').val($('#cap_disp').value<string>())
        $('#city').val($('#city_disp').value<string>())
        $('#number').val($('#number_disp').value<string>())
        $('#prov').val($('#prov_disp').value<string>())
        $('#street').val($('#street_disp').value<string>())
        $('#country').val($('#country_disp').value<string>())

        var currentCountry = $("#country").find('option:selected').data('eu');

        if (currentCountry == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required', 'required');
        } else if (currentCountry == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required', 'required');
        } else if (currentCountry != undefined) {
            $('#cfpiva-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
        }

    });

    $("#country").change(function () {
        var selected = $(this).find('option:selected');
        var requiredVat = selected.data('eu');

        if (requiredVat == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required', 'required');
            $('#cfpiva').val("")
        } else if (requiredVat == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required', 'required');
            $('#cfpiva').val("")
            $('#pec').val("")
            $('#sdi').val("")
        } else {
            $('#cfpiva-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
            $('#cfpiva').val("")
        }

    });

    $('#user-update-data-form').validate({
        errorPlacement: () => { },
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => { },
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => { },
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    function submitUserUpdateForm(form: JQuerySelector) {
        const $form = $(form);
        const enabledInputs = $form.find(':input:not([disabled]):not([readonly])');

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function () {
                enabledInputs.prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function () {
                enabledInputs.prop('disabled', false);
            }
        });
    }


    $('#password-change-form').validate({
        submitHandler: function (form) {
            const $form = $(form)
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    }
                    else if (data.errors.message as string in msg) {
                        showMessageBox('danger', msg[data.errors.message as keyof Translations]);
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });


    // profile consent ajax
    $('#profile-consent-form').submit(function (event) {
        event.preventDefault();
        var $form = $(this);

        type Consent = { consentId: string, value: boolean };

        var formData: {userConsents: Consent[]} = {
            userConsents: []
        };

        $('.profile-privacy-input').each(function () {
            var obj: Consent = {consentId: $(this).attr("name"), value: $(this).is(":checked")};
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function (data) {

            },
            success: function (data) {

            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            }
        });
    });

    $('.edit-order').on('click', function (event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/delegate/easydropshipping/bookedtocart',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    alert('Cannot edit this order.');
                }
            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.delete-order').on('click', function (event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/cancel',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.reload();
                } else {
                    alert('Cannot cancel this order.');
                }
            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.pay-order').on('click', function (event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/initialize',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/checkout/payment';
                } else {
                    alert('Cannot pay this order.');
                }
            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.new-return-request form').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $(this).prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RETURN_REQUEST']);
                } else {
                    // TODO translatable error messages
                    showMessageBox('danger', msg['MSG_RETURN_REQUEST_ERROR']);
                }
            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $(this).prop('disabled', false);
            }
        });
    });

    $('form.cancel-return-request-form').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/profile/returnrequests/1';
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            error: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
}