function subscribeComponent() {
    $('#subscribe-form').validate({
        errorPlacement: customErrorPlacement,
        rules: {
            password1: {
                complexPassword: true,
            },
            password2: {
                equalTo: '#password1',
            },
        },
        submitHandler: function (form, event) {
            const $form = $(form);
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function () {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        const dataLayer = window.dataLayer as object[];
                        if (dataLayer) {
                            pushGTMEventWithCallback(500, {
                                event: 'sign_up',
                                eventCallback: GTMCallback(() => {
                                    window.location = $form.data('redirectsuccess');
                                })
                            });
                        }
                        else {
                            window.location = $form.data('redirectsuccess');
                        }
                    }
                    else if (data.errors.message as string in msg) {
                        showMessageBox('danger', msg[data.errors.message as keyof Translations]);
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function () {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('#password-new-form-email').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_TOKEN_SENT']);
                        const $tokenForm = $('#password-new-form-token');
                        $form.addClass('hide');
                        $tokenForm.removeClass('hide');
                        copyFormInputs('#password-new-form-email', '#password-new-form-token', {
                            'email': 'email'
                        });
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            })
        }
    });

    $('#i_country_id').on('change', function () {
        const eu = $('#i_country_id').find('option:selected').data('eu');
        if (eu == "IT") {
            $('#sdi,#pec')
                .disabled(false)
                .closest('.columns').removeClass('hide');
        } else {
            $('#sdi,#pec')
                .disabled(true)
                .closest('.columns').addClass('hide');
        }
    })

    $('#password-new-form-token').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_RESET'], function () {
                            window.location.href = $form.data('nexturl');
                        });
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            })
        }
    });

    $('#subscribe-form .radio').on('change', function () {
        $('#subscribe-form .comments').slideDown();
    });

    toggleInterests();
    $('#subscribe-form #newsletter').on('change', toggleInterests);

    function toggleInterests() {
        if ($('#subscribe-form #newsletter').is(':checked')) {
            $('#show-newsletter').slideDown()
                .find('input').prop('disabled', false);
        }
        else {
            $('#show-newsletter').slideUp()
            .find('input').prop('disabled', true);
        }
    }

    $('#subscribe-multistep .steps').on('multi-step:next', function (event) {
        const activeStepInputs = $('#subscribe-multistep .step.active').find('input, select');
        if (activeStepInputs.length > 0 && !activeStepInputs.valid()) {
            event.stopPropagation();
        }
    });

    monitorInput('#subscribe-form', 'i_country_id',
        function(input) {
            const eu = $(input).find('option:selected').data('eu');
            return !!eu && eu != 'EXTRA-EU';
        },
        () => enableInput('#subscribe-form', 'cfpiva'),
        () => disableInput('#subscribe-form', 'cfpiva')
    );
}